import React, { useEffect, useState } from 'react'
import './RankingSqueeze.css'
import { Row, Col, Typography } from 'antd'
import leftRip from '../../assets/Ripped_Paper_Left.png'
import rightRip from '../../assets/Ripped_Paper_Right_Cropped.png'
import opineLogo from '../../assets/OpineLogoBlackBar.svg'
import opineQR from '../../assets/OPINE_QR.png'
import PollResults from '../../components/PollResults'

export default function RankingSqueeze({prompt}){
  const [ pollAnswers, setPollAnswers ] = useState()

  useEffect(()=>{
    let pollAnswersArr = []
    prompt.responses.forEach( (response,i) => {
      let obj = {}
      obj = {
        ...obj, 
        barWidth:  0,
        response: response, 
        count: prompt.responseCountByChoice ? prompt.responseCountByChoice[response] : 0
      }
      
      pollAnswersArr.push(obj)
    })
    pollAnswersArr.sort((a,b) => a.count - b.count)
    setPollAnswers(pollAnswersArr)
  },[prompt])
  
  return(
    <Row className='widget-container'>
      <Row className='squeeze-container' id='rank-container'>
        <Col className='squeeze-center' id='rank-center'/>
        <Col className='squeeze-left-rip' style={{backgroundImage:`url(${leftRip})`}}/>
        <Col className='squeeze-right-rip' style={{backgroundImage:`url(${rightRip})`}}/>
        <Row className='squeeze-content-container'>
          <Row className='squeeze-top-container'>
            <img src={opineLogo} alt='Opine Logo'/>
            <Typography.Text className='squeeze-content-prompt-text'>{prompt.text}</Typography.Text>
          </Row>
          <Row className='ranking-squeeze-responses-container'>
            {pollAnswers 
                ? 
                  pollAnswers.map((response,i) => {
                    return <PollResults key={response.response} responseObj={response} rank={i+1}/>
                  })
                :
                null
              }
          </Row>
          <Row gutter={16} className='squeeze-cta-container'>
            <Col>
              <img className='squeeze-qr' src={opineQR} alt='Download Opine on the App Store'/>
            </Col>
            <Col className='squeeze-cta-text-container'>
              <Typography.Text className='squeeze-cta-top'>Join the Conversation!</Typography.Text>
              <Typography.Text className='squeeze-cta-bottom'>Download the app!</Typography.Text>
            </Col>
          </Row>
        </Row>
      </Row>
    </Row>
  )
}