import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'
import logoMark from '../../assets/OpineLogoMark.svg'
import wordMark from '../../assets/OpineWordMark.svg'
import PollResults from '../../components/PollResults'
import './FourUpLowerThird.css'

export default function FourUpLowerThird({prompt}){
  const [ pollAnswers, setPollAnswers ] = useState([])

  useEffect(()=>{
    setPollAnswers([])
    if(!prompt.responseCountByChoice){
      console.log('nope')
      let arr = []
      prompt.responses.forEach(response => {
        arr.push({barWidth: 0, response: response, count: 0})
      })
      setPollAnswers(arr)
    }else if(prompt.responseCountByChoice){
      console.log('yep')
      let pollAnswersArr = []
      prompt.responses.forEach( (response,i) => {
        pollAnswersArr.push({
          barWidth:  prompt.responseCountByChoice[response] / prompt.responseCount * 100,
          response: response, 
          count: prompt.responseCountByChoice[response]
        })
      })
      pollAnswersArr.sort((a,b) => b.barWidth - a.barWidth)
      console.log('pollAnswers',pollAnswers)
      setPollAnswers(pollAnswersArr)
    }
  },[prompt])

  return(
    <Row className='widget-container'>
      <Row id='two-up-lower-third'>
        <Col className='lower-third-branding-container'>
          <img src={logoMark} className='lower-third-branding-container-logomark' alt='Opine Logo Mark'/>
          <img src={wordMark} className='lower-third-branding-container-wordmark' alt='Opine Word Mark'/>
        </Col>
        <Col span={24} className='lower-third-prompt-text-container'>
          <Col className='lower-third-prompt-text-wrapper'>
            {prompt.ads 
              ? 
              <Row className='lower-third-sponsor-wrapper'>
                <Typography.Text className='lower-third-sponsor-lable'>Sponsored By:</Typography.Text>
                <img className='lower-third-prompt-sponsor' src={prompt.ads[0].sponsorLogo} alt={prompt.ads[0].sponsorName}/> 
              </Row>
              : 
                null
            }
            <Typography.Text className='lower-third-prompt-text'>{prompt.text}</Typography.Text>
          </Col>
        </Col>
        <Col className='lower-third-responses-container'>
          {pollAnswers 
            ? 
              pollAnswers.map(response => {
                  return <PollResults key={response.response} responseObj={response}/>
                })
            :
            null
          }
        </Col>
      </Row>
    </Row>
  )
}