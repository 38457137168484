import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router'
import './KisweOverlay.css'
import { FirebaseContext } from './services/firebase'
import FourUpLowerThird from './widgets/FourUpLowerThird/FourUpLowerThird'
import MediaTwoUpSqueeze from './widgets/MediaTwoUpSqueeze/MediaTwoUpSqueeze'
import TwoUpLowerThird from './widgets/TwoUpLowerThird/TwoUpLowerThird'
import RankingSqueeze from './widgets/RankingSqueeze/RankingSqueeze'
import MultipleChoiceSqueeze from './widgets/MultipleChoiceSqueeze/MultipleChoiceSqueeze'
import SlidingScaleLowerThird from './widgets/SlidingScaleLowerThird/SlidingScaleLowerThird'
import { Row, Typography } from 'antd'

export default function KisweOverlay(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const publisherId = history.location.pathname.split('/')[1]
  const [ prompt, setPrompt ] = useState()
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(()=>{
    if(!publisherId)return
    firebase.firestore.collection('kiswe').doc(publisherId).onSnapshot(docRef => {
      const promptId = docRef.data().promptId
      firebase.firestore.collection('prompts').doc(promptId)
      .onSnapshot(docRef => {
        if(docRef.exists){
          const doc = docRef.data()
          setPrompt(doc)
        } else {
          setPrompt(false)
        }
        // setIsLoading(false)
      })
    })
  },[publisherId, firebase.firestore])


  useEffect(()=>{
    if(!publisherId)return
      firebase.firestore.collection('prompts')
        .where('publisherId', '==', publisherId)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot(snap => {
          snap.docs.forEach(docRef => {
            console.log('docRef',docRef.data().text )
            if(docRef.exists){
              const doc = docRef.data()
              setPrompt(doc)
            } else {
              setPrompt(false)
            }
          }
          )
        setIsLoading(false)
      })
  },[publisherId, firebase.firestore])

  const singleChoiceCase='single choice',
        multipleChoiceCase='multiple choice',
        slidingScaleCase='sliding scale',
        rankingCase='ranking'

  if(isLoading){
    return <p>Loading...</p> 
  } 
  
  if(prompt && prompt.responseContentType === 'image'){
    return <MediaTwoUpSqueeze prompt={prompt}/>
  }

  if(prompt && prompt.responseContentType === 'text'){
    if(prompt.responses.length > 2){
      switch(prompt.responseType){
        case singleChoiceCase: 
          return <FourUpLowerThird prompt={prompt}/>

        case multipleChoiceCase: 
          return <MultipleChoiceSqueeze prompt={prompt}/>

        case rankingCase: 
          return <RankingSqueeze prompt={prompt} />

        default: return(null)
      }
    }else if(prompt.responses.length <= 2){
      switch(prompt.responseType){
        case singleChoiceCase: 
          return <TwoUpLowerThird prompt={prompt}/>
    
        case slidingScaleCase: 
          return <SlidingScaleLowerThird prompt={prompt}/>
    
        default: return(null)
      }
    }
  }
  
  if(!prompt){
    return(
      <Row style={{display:'flex', flexDirection:'column'}}>
        <Typography.Title>No Selected Prompts</Typography.Title>
        <Typography.Title level={4}>No Selected Prompts</Typography.Title>
      </Row>
      ) 
      
  }
}