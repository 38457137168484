import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'
import logoMark from '../../assets/OpineLogoMark.svg'
import wordMark from '../../assets/OpineWordMark.svg'
import './SlidingScaleLowerThird.css'
import {ReactComponent as ScaleBubble} from '../../assets/Scale_Bubble.svg'

export default function SlidingScaleLowerThird({prompt}){
  const { responseCountByChoice } = prompt
  const [ scaleResponseAvg, setScaleResponseAvg ] = useState()
  const [ scaleResponseLabel, setScaleResponseLabel ] = useState()
  const [ leftMargin, setLeftMargin ] = useState()

  useEffect(()=>{
    if(responseCountByChoice){
      let sum = 0
      for( let val in responseCountByChoice){
          sum += parseInt(val)
      }
      let avg = Math.round(sum / prompt.responseCount)
      console.log('prompt', prompt)
      console.log('responseCountByChoice', responseCountByChoice)
      console.log('avg', avg)
      let percent = avg/prompt.responses[1]*100
      console.log('percent', percent)
      setScaleResponseAvg(percent)
      setScaleResponseLabel(avg)
    }else{
      setScaleResponseAvg(0)
    }
  },[responseCountByChoice, prompt.responseCount])

  useEffect(()=>{
    if(scaleResponseAvg <= 5){
      setLeftMargin(0)
    }else if(scaleResponseAvg > 5 && scaleResponseAvg <= 95){
      setLeftMargin(scaleResponseAvg - 4.5)
    }else{
      setLeftMargin(91)
    }
  },[scaleResponseAvg])

  return(
    <Row className='widget-container'>
      <Col id='sliding-scale-lower-third'>

        <Row className='sliding-scale-lower-third-top'>
          <Col className='lower-third-branding-container'>
            <img src={logoMark} className='lower-third-branding-container-logomark' alt='Opine Logo Mark'/>
            <img src={wordMark} className='lower-third-branding-container-wordmark' alt='Opine Word Mark'/>
          </Col>
          <Col className='sliding-scale-lower-third-response-container'>
            <Col className='sliding-scale-bubble-container' style={{ marginLeft: `${leftMargin}%`}}>
              <ScaleBubble id='sliding-scale-bubble'/>
              <Typography.Text id='sliding-scale-bubble-text'>
                {scaleResponseLabel}
              </Typography.Text>
            </Col>
            <Col id='sliding-scale-response-bar' style={{ width:`${scaleResponseAvg}%` }}/>
            <Col id='sliding-scale-static-bar'/>
          </Col>
        </Row>

        <Col span={24} className='lower-third-prompt-text-container'>
          <Col className='lower-third-prompt-text-wrapper'>
            {prompt.ads 
              ? 
              <Row className='lower-third-sponsor-wrapper'>
                <Typography.Text className='lower-third-sponsor-lable'>Sponsored By:</Typography.Text>
                <img className='lower-third-prompt-sponsor' src={prompt.ads[0].sponsorLogo}  alt={prompt.ads[0].sponsorName}/> 
              </Row>
              : 
                null
            }
            <Typography.Text className='lower-third-prompt-text'>{prompt.text}</Typography.Text>
          </Col>
        </Col>

        {/* <Col className='sliding-scale-lower-third-response-container'>
          <Col className='sliding-scale-bubble-container' style={{ marginLeft: `${leftMargin}%`}}>
            <ScaleBubble id='sliding-scale-bubble'/>
            <Typography.Text id='sliding-scale-bubble-text'>
              {scaleResponseAvg}
            </Typography.Text>
          </Col>
          <Col id='sliding-scale-response-bar' style={{ width:`${scaleResponseAvg}%` }}/>
          <Col id='sliding-scale-static-bar'/>
        </Col> */}
      </Col>
    </Row>
  )
}