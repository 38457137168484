import KisweOverlay from "./KisweOverlay"
import NotFound from "./404"
import { Route, Switch } from 'react-router-dom'

export default function Routes(){

  return(
    <Switch>
      <Route path='/:promptId' component={KisweOverlay} />
      <Route component={NotFound} />
    </Switch>
  )
}