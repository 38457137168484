import React from 'react'
import { Row, Typography } from 'antd'

export default function NoFound(){

  return(
    <Row style={{height:'100%'}} justify='center' align='middle'>
      <Typography.Title style={{background:'white', padding: 20}}>Oops... we can't find the page you're looking for.</Typography.Title>
    </Row>
  )
}