import { useHistory } from 'react-router'
import { useEffect, useState } from 'react';
import { Row, Col, Typography, Input, Form, Button } from 'antd';
import Routes from './Routes'

function App() {
  const history = useHistory()
  const [form] = Form.useForm();
  const [ publisherId, setPublisherId ] = useState()

  // console.log('publisherId', publisherId)
  // console.log('formSubmission', formSubmission)

  useEffect(()=>{
    let pid = history.location.pathname.split('/')[1]
    if(pid){
      setPublisherId(pid)
    }else{
      setPublisherId(false)
    }

  },[history])

  const onFinish = (values) => {
    setPublisherId(values.pid)
    form.resetFields()
    history.push(`/${values.pid}`)
  };

  return (
    publisherId
    ?
      <Routes/>
    :
      <Row style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:25}}>
        <Col style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Typography.Title>No Publisher ID Detected</Typography.Title>
          <Typography.Title level={3} style={{opacity:0.75}}>Please add your publisher ID to the end of the URL or submit it to the box below.</Typography.Title>
          <Form
            layout='vertical'
            form={form}
            style={{width:'100%'}}
            onFinish={onFinish}
            autoComplete='off'
          >
            <Form.Item label="Publisher ID" name="pid">
              <Input size='large' placeholder="Publisher ID" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
  );
}

export default App;
