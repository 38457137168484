import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'
import logoMark from '../../assets/OpineLogoMark.svg'
import wordMark from '../../assets/OpineWordMark.svg'
import './TwoUpLowerThird.css'

export default function TwoUpLowerThird({prompt}){
  const [ leftPercentage, setLeftPercentage ] = useState(50)
  const [ rightPercentage, setRightPercentage ] = useState(50)

  useEffect(()=>{
    if(!prompt)return
    if(!prompt.responseCountByChoice)return

    if(prompt.responseCountByChoice){
      let response1Val = prompt.responseCountByChoice[prompt.responses[0]]
      let response2Val = prompt.responseCountByChoice[prompt.responses[1]]
      let total = response1Val + response2Val
      let res1Percent = Math.round(response1Val / total * 100)
      let res2Percent = Math.round(response2Val / total * 100)
      setLeftPercentage(res1Percent)
      setRightPercentage(res2Percent)
    }
  },[prompt])

  return(
    <Row className='widget-container'>
      <Row id='two-up-lower-third'>
        <Col className='lower-third-branding-container'>
          <img src={logoMark} className='lower-third-branding-container-logomark' alt='Opine Logo Mark'/>
          <img src={wordMark} className='lower-third-branding-container-wordmark' alt='Opine Word Mark'/>
        </Col>
        <Col span={24} className='lower-third-prompt-text-container'>
          <Col className='lower-third-prompt-text-wrapper'>
            {prompt.ads 
              ? 
              <Row className='lower-third-sponsor-wrapper'>
                <Typography.Text className='lower-third-sponsor-lable'>Sponsored By:</Typography.Text>
                <img className='lower-third-prompt-sponsor' src={prompt.ads[0].sponsorLogo}  alt={prompt.ads[0].sponsorName}/> 
              </Row>
              : 
                null
            }
            <Typography.Text className='lower-third-prompt-text'>{prompt.text}</Typography.Text>
          </Col>
        </Col>
        <Col className='lower-third-responses-container'>
          <Col className='responses-container-response-text-container' id='left-text-clip'>
            <Typography.Text style={{color:'white'}}>{prompt.responses[0]}</Typography.Text>
          </Col>
          <Col className='responses-container-graph-container'>
            <Col className='lower-third-percentage-container-left'>
              <Typography.Text className='lower-third-percentage-text-left'>{leftPercentage}%</Typography.Text>
            </Col>
            <Col style={{width:`${leftPercentage}%`}} className='responses-container-graph-container-bar-left'/>
            <Col className='response-container-graph-container-spacer'/>
            <Col style={{width:`${rightPercentage}%`}} className='responses-container-graph-container-bar-right'/>
            <Col className='lower-third-percentage-container-right'>
              <Typography.Text className='lower-third-percentage-text-right'>{rightPercentage}%</Typography.Text>
            </Col>
          </Col>
          <Typography.Text className='responses-container-response-text-container' id='right-text-clip'>{prompt.responses[1]}</Typography.Text>
        </Col>
      </Row>
    </Row>
  )
}