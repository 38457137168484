import React from 'react'
import './PollResults.css'
import { Row, Col, Typography } from 'antd'
import numeral from 'numeral'

export default function PollResults({responseObj, rank}){
  const { barWidth, response, image } = responseObj
  
  return(
    <Row className='poll-results'>
      {image 
        ? 
          <img className='poll-results-response-img' src={image} alt={response}/>
        :
          null
      }
      <Col className='poll-results-bar-container'>
        <Row className='poll-results-text-container'>
          <Typography.Text className='poll-results-percentage'>{rank ? `${numeral(rank).format('Oo')}` : `${Math.round(barWidth)}%`}</Typography.Text>
          <Typography.Text className='poll-results-response-text'>{response}</Typography.Text>
        </Row>
        <Row className='poll-results-bar' style={{ width:`${barWidth}%`}}/>
      </Col>
    </Row>
  )
}