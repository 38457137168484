import React, { createContext } from 'react'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
// import firebaseConfig from "./config";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
app.initializeApp(config)

const FirebaseContext = createContext(null)
export { FirebaseContext }

const FirebaseProvider = ({ children }) => {

  let firebase = {
    firestore: app.firestore().doc('versions/v1-prod'),
    auth: app.auth()
  }
  

  return (
      <FirebaseContext.Provider value={firebase}>
          {children}
      </FirebaseContext.Provider>
  )
}

export default FirebaseProvider